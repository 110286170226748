*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding:0;
}
html {
  height: 100vh;
  width: 100vw;
}
body {
  min-height: 100%;
  width: 100%;
  background: #F9FAFC;
  overflow-x: hidden;
  padding: 0 !important;
}
.App {
  width: 100%;
  height: 100%;
  padding-bottom: 27px;
}
